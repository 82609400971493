import { PRODUCT_CODES } from '@/constants'
import TabbedContentDisplay from '../TabbedContentDisplay/TabbedContentDisplay'

const config = {
    [`${PRODUCT_CODES.ZENHAVEN}--comfort-level`]: {
        title: 'Dual-sided for your perfect comfort level',
        hasMultipleVideos: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic',
            'generic'
        ],
        videoName: [
            'side-sleeper',
            'stomach-sleeper'
        ],
        videoAlt: 'comfort level options'
    },
    [`${PRODUCT_CODES.SAATVA_CLASSIC}--comfort-level`]: {
        title: 'Choose your comfort level',
        hasMultipleVideos: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic',
            'generic',
            'generic'
        ],
        videoName: [
            'side-sleeper',
            'all-sleeper',
            'stomach-sleeper'
        ],
        videoAlt: 'comfort level options'
    },
    [`${PRODUCT_CODES.LOOM_AND_LEAF}--comfort-level`]: {
        title: 'Choose your comfort level',
        hasMultipleVideos: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic',
            'generic'
        ],
        videoName: [
            'side-sleeper',
            'stomach-sleeper'
        ],
        videoAlt: 'comfort level options'
    },
    [`${PRODUCT_CODES.SAATVA_CONTOUR5}--comfort-level`]: {
        title: 'Choose your comfort level',
        hasMultipleVideos: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic',
            'generic'
        ],
        videoName: [
            'side-sleeper',
            'stomach-sleeper'
        ],
        videoAlt: 'comfort level options'
    },
    [`${PRODUCT_CODES.CLASSIC_STARTER_BUNDLE}--comfort-level`]: {
        title: 'Choose your perfect comfort level',
        hasMultipleVideos: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic',
            'generic',
            'generic'
        ],
        videoName: [
            'side-sleeper',
            'all-sleeper',
            'stomach-sleeper'
        ],
        videoAlt: 'comfort level options'
    },
    [`${PRODUCT_CODES.SAATVA_HD}--comfort-level`]: {
        title: 'Comfort for every body',
        hasMultipleVideos: true,
        hideTabTitles: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic'
        ],
        videoName: [
            'motion-isolation'
        ],
        videoAlt: 'comfort level options'
    },
    [`${PRODUCT_CODES.MEMORY_FOAM_HYBRID}--comfort-level`]: {
        title: 'The perfect medium firmness',
        hasMultipleVideos: true,
        hideTabTitles: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic'
        ],
        videoName: [
            'motion-isolation'
        ],
        videoAlt: 'comfort level options'
    },
    [`${PRODUCT_CODES.SAATVA_LATEX_HYBRID}--comfort-level`]: {
        title: 'The perfect medium firmness',
        hasMultipleVideos: true,
        hideTabTitles: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic'
        ],
        videoName: [
            'motion-isolation'
        ],
        videoAlt: 'comfort level options'
    },
    [`${PRODUCT_CODES.SAATVA_RX}--comfort-level`]: {
        title: 'Plush comfort without sacrificing support',
        hasMultipleVideos: true,
        hideTabTitles: true,
        content: TabbedContentDisplay,
        videoFolder: [
            'generic'
        ],
        videoName: [
            'side-sleeper'
        ],
        videoAlt: 'comfort level options'
    },
    [PRODUCT_CODES.ADJUSTABLE_BASE]: {
        title: 'Elevate your comfort with our adjustable base',
        content: () => ['Designed for weightless sleep and effortless relaxation, our adjustable base helps you make the most of your precious time in bed.'],
        videoFolder: 'adjustable-base',
        videoName: 'queen',
        videoAlt: 'Video of the Saatva Adjustable Base in different configurations',
    },
    [PRODUCT_CODES.ADJUSTABLE_BASE_PLUS]: {
        title: 'Elevate your comfort with our adjustable base',
        content: () => ['Designed for weightless sleep and effortless relaxation, our adjustable base helps you make the most of your precious time in bed.'],
        videoFolder: 'adjustable-base-plus',
        videoName: 'queen',
        videoAlt: 'Video of the Saatva Adjustable Base Plus in different configurations',
    },
}

export { config }
